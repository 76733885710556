sdltModule.factory('SDLT', ['$http', function ($http) {

    return {
        getDates: function (data) {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/sdlt/' + SDLT_ID + '/dates',
                method: 'POST',
                data: data
            }).success(function (response) {
                return response.data;
            });

            return promise;
        }
    }

}]);