overlapReliefModule.factory('OverlapRelief', ['$http', function ($http) {

    return {
        show: function () {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/overlap-relief/' + OVERLAP_RELIEF_ID + '',
                method: 'GET'
            }).success(function (response) {
                return response.data;
            });

            return promise;
        },
        getDates: function (data) {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/overlap-relief/' + OVERLAP_RELIEF_ID + '/dates',
                method: 'POST',
                data: data
            }).success(function (response) {
                return response.data;
            });

            return promise;
        }
    }

}]);