linkedTransactionModule.controller('LinkedTransactionCtrl', ['$scope', '$element', 'SweetAlert', function ($scope, $element, SweetAlert) {

    $scope.premiumValues = PREMIUM_VALUES;
    $scope.npvValues = NPV_VALUES;
    $scope.considerationValues = CONSIDERATION_VALUES;

    $scope.isConsideration = function () {
        return $scope.freeholdOrLeasehold == 'Freehold';
    };

    $scope.addValue = function () {
        if ($scope.isConsideration()) {
            $scope.considerationValues.push(0);
        } else {
            $scope.premiumValues.push(0);
            $scope.npvValues.push(0);
        }
    };

    $scope.removeValue = function (index) {
        if ($scope.isConsideration()) {
            $scope.considerationValues.splice(index, 1);
        } else {
            $scope.premiumValues.splice(index, 1);
            $scope.npvValues.splice(index, 1);
        }
    };

    $scope.formValid = function () {
        var valid = true;

        angular.forEach($scope.npvValues, function (val, index) {
            console.log(val, index);
            if (val == '' || val == 0) {
                valid = false;
            }
        });

        return valid;
    };

    $scope.submitCalc = function (event) {
        event.preventDefault();

        SweetAlert.swal({
            title: "Are you sure?",
            text: "Please confirm that the data entered is correct as there is a charge for every time a calculation is run",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            closeOnConfirm: true
        },
        function(isConfirm) {
            if (isConfirm) {
                $element.find('#linkedTransactionForm').submit();
            }
        });
    };

}]);