baseModule.controller('BaseCtrl', ['$scope', '$element', '$window', 'SweetAlert', function ($scope, $element, $window, SweetAlert) {

    $scope.print = function () {
        $window.print();
    };

    $scope.confirmDestroy = function (event) {
        event.preventDefault();

        SweetAlert.swal({
                title: "Are you sure?",
                text: "Please confirm that you want to delete this calculation, this cannot be undone.",
                type: "warning",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                closeOnConfirm: true
            },
            function(isConfirm) {
                if (isConfirm) {
                    $element.find('#' + event.currentTarget.getAttribute('form')).submit();
                }
            });

        return false;
    }

}]);