overlapReliefModule.controller('OverlapReliefCtrl', ['$scope', '$element', 'moment', 'OverlapRelief', 'SweetAlert', function ($scope, $element, moment, OverlapRelief, SweetAlert) {

    $scope.calc = {};

    $scope.getDates = function () {
        if ( ! $scope.loading) {
            OverlapRelief.getDates($scope.calc).then(function (response) {
                $scope.calc.existingLeaseYear1RentStartDate1 = response.data.years.existingLeaseYear1RentStartDate1;
                $scope.calc.existingLeaseYear2RentStartDate1 = response.data.years.existingLeaseYear2RentStartDate1;
                $scope.calc.existingLeaseYear3RentStartDate1 = response.data.years.existingLeaseYear3RentStartDate1;
                $scope.calc.existingLeaseYear4RentStartDate1 = response.data.years.existingLeaseYear4RentStartDate1;
                $scope.calc.existingLeaseYear5RentStartDate1 = response.data.years.existingLeaseYear5RentStartDate1;

                if ($scope.calc.existingLeaseYear2RentStartDate1 !== null && $scope.calc.existingLeaseYear2NumRents < 1) {
                    $scope.calc.existingLeaseYear2NumRents = 1;
                }

                if ($scope.calc.existingLeaseYear3RentStartDate1 !== null && $scope.calc.existingLeaseYear3NumRents < 1) {
                    $scope.calc.existingLeaseYear3NumRents = 1;
                }

                if ($scope.calc.existingLeaseYear3RentStartDate1 !== null && $scope.calc.existingLeaseYear3NumRents < 1) {
                    $scope.calc.existingLeaseYear3NumRents = 1;
                }

                if ($scope.calc.existingLeaseYear4RentStartDate1 !== null && $scope.calc.existingLeaseYear4NumRents < 1) {
                    $scope.calc.existingLeaseYear4NumRents = 1;
                }

                $scope.calc.newLeaseYear1RentStartDate1 = response.data.years.newLeaseYear1RentStartDate1;
                $scope.calc.newLeaseYear2RentStartDate1 = response.data.years.newLeaseYear2RentStartDate1;
                $scope.calc.newLeaseYear3RentStartDate1 = response.data.years.newLeaseYear3RentStartDate1;
                $scope.calc.newLeaseYear4RentStartDate1 = response.data.years.newLeaseYear4RentStartDate1;
                $scope.calc.newLeaseYear5RentStartDate1 = response.data.years.newLeaseYear5RentStartDate1;

                if ($scope.calc.newLeaseYear2RentStartDate1 !== null && $scope.calc.newLeaseYear2NumRents < 1) {
                    $scope.calc.newLeaseYear2NumRents = 1;
                }

                if ($scope.calc.newLeaseYear3RentStartDate1 !== null && $scope.calc.newLeaseYear3NumRents < 1) {
                    $scope.calc.newLeaseYear3NumRents = 1;
                }

                if ($scope.calc.newLeaseYear3RentStartDate1 !== null && $scope.calc.newLeaseYear3NumRents < 1) {
                    $scope.calc.newLeaseYear3NumRents = 1;
                }

                if ($scope.calc.newLeaseYear4RentStartDate1 !== null && $scope.calc.newLeaseYear4NumRents < 1) {
                    $scope.calc.newLeaseYear4NumRents = 1;
                }
            });
        }
    };

    $scope.submitCalc = function (event) {
        event.preventDefault();

        SweetAlert.swal({
            title: "Are you sure?",
            text: "Please confirm that the data entered is correct as there is a charge for every time a calculation is run",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            closeOnConfirm: true
        },
        function(isConfirm) {
            if (isConfirm) {
                $element.find('#overlapReliefForm').submit();
            }
        });
    };

    $scope.datePattern = "(?:(?:0[1-9]|[12][0-9])[\/\\-. ]?(?:0[1-9]|1[0-2])|(?:30[\/\\-. ]?(?:0[13-9]|1[0-2]))|(?:31[\/\\-. ]?(?:0[13578]|1[02])))[\/\\-. ]?(?:1[1-9]|2[0-9]|3[0-9])[0-9]{2}";

    $scope.loading = true;

    $scope.init = function (calculationId) {
        if (calculationId) {
            OverlapRelief.show().then(function (response) {
                $scope.calc = response.data;
                $scope.loading = false;
            });
        } else {
            $scope.loading = false;
            $scope.getDates();
        }
    };

}]);