leaseRenewalModule.factory('LeaseRenewal', ['$http', function ($http) {
    
    return {
        show: function () {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/lease-renewal/' + LEASE_RENEWAL_ID + '',
                method: 'GET'
            }).success(function (response) {
                return response.data;
            });

            return promise;
        },
        getDates: function (data) {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/lease-renewal/' + LEASE_RENEWAL_ID + '/dates',
                method: 'POST',
                data: data
            }).success(function (response) {
                return response.data;
            });

            return promise;
        },
        getGrowingLeaseVacantDates: function (data) {
            var promise = $http({
                url: '/api/transactions/' + TRANSACTION_ID + '/lease-renewal/' + LEASE_RENEWAL_ID + '/growing-lease-dates',
                method: 'POST',
                data: data
            }).success(function (response) {
                return response.data;
            });

            return promise;
        }
    }

}]);