leaseRenewalModule.controller('LeaseRenewalCtrl', ['$scope', '$timeout', '$element', 'moment', 'LeaseRenewal', 'SweetAlert', function ($scope, $timeout, $element, moment, LeaseRenewal, SweetAlert) {

    $scope.growingLeaseTermEndDates = [];
    $scope.numYears = 1;

    $scope.getDates = function () {
        if ( ! $scope.loading) {
            LeaseRenewal.getDates($scope.calc).then(function (response) {
                $scope.calc.rcap = response.data.rcap;
                $scope.calc.year1RentStartDate1 = response.data.years.year1RentStartDate1;
                $scope.calc.year2RentStartDate1 = response.data.years.year2RentStartDate1;
                $scope.calc.year3RentStartDate1 = response.data.years.year3RentStartDate1;
                $scope.calc.year4RentStartDate1 = response.data.years.year4RentStartDate1;
                $scope.calc.year5RentStartDate1 = response.data.years.year5RentStartDate1;

                $scope.numYears = response.data.numYears;

                setTimeout(function () {
                    $scope.$apply(function () {
                        if ($scope.numYears < 5) {
                            $scope.calc.year5NumRents = 1;
                        } else {
                            if ($scope.calc.year5NumRents == 0) {
                                $scope.calc.year5NumRents = 1;
                            }
                        }

                        if ($scope.numYears < 4) {
                            $scope.calc.year4NumRents = 1;
                        } else {
                            if ($scope.calc.year4NumRents == 0) {
                                $scope.calc.year4NumRents = 1;
                            }
                        }

                        if ($scope.numYears < 3) {
                            $scope.calc.year3NumRents = 1;
                        } else {
                            if ($scope.calc.year3NumRents == 0) {
                                $scope.calc.year3NumRents = 1;
                            }
                        }

                        if ($scope.numYears < 2) {
                            $scope.calc.year2NumRents = 1;
                        } else {
                            if ($scope.calc.year2NumRents == 0) {
                                $scope.calc.year2NumRents = 1;
                            }
                        }
                    });
                }, 1000);
            });

            if ($scope.calc.leaseType == 'Growing Lease') {
                $scope.getGrowingLeaseVacantDates();
            }
        }
    }

    $scope.getGrowingLeaseVacantDates = function () {
        LeaseRenewal.getGrowingLeaseVacantDates($scope.calc).then(function (response) {
            $scope.growingLeaseTermEndDates = response.data;

            if (undefined !== GOWING_LEASE_VACANT_TERM_END_DATE) {
                if ( ! $scope.calc.growingLeaseVacantTermEndDate) {
                    $scope.calc.growingLeaseVacantTermEndDate = GOWING_LEASE_VACANT_TERM_END_DATE.replace('string:', '');
                }
            }
        });
    }

    $scope.submitCalc = function (event) {
        event.preventDefault();

        if ($scope.calc.leaseType == 'New Lease') {
            $element.find('#leaseRenewalForm').submit();
        } else {
            SweetAlert.swal({
                    title: "Are you sure?",
                    text: "Please confirm that the data entered is correct as there is a charge for every time a calculation is run",
                    type: "warning",
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $element.find('#leaseRenewalForm').submit();
                    }
                });
        }
    };

    $scope.datePattern = "(?:(?:0[1-9]|[12][0-9])[\/\\-. ]?(?:0[1-9]|1[0-2])|(?:30[\/\\-. ]?(?:0[13-9]|1[0-2]))|(?:31[\/\\-. ]?(?:0[13578]|1[02])))[\/\\-. ]?(?:1[1-9]|2[0-9]|3[0-9])[0-9]{2}";

    $scope.loading = true;

    $scope.init = function (calculationId) {
        if (calculationId) {
            LeaseRenewal.show().then(function (response) {
                $scope.calc = response.data;

                $scope.loading = false;

                // Call the get dates method to get the lease renewal dates
                $scope.getDates();
            });
        } else {
            $scope.calc = {};
            $scope.calc.rcap = RCAP;

            $scope.loading = false;

            $timeout(function () {
                $scope.getDates();
            }, 1000);
        }
    };

}]);