sdltModule.controller('SDLTCtrl', ['$scope', 'moment', 'SDLT', function ($scope, moment, SDLT) {

    $scope.calc = {};

    $scope.getDates = function () {
        SDLT.getDates($scope.calc).then(function (response) {
            $scope.calc.rcap = response.data.rcap;
            $scope.calc.year1RentStartDate1 = response.data.years.year1RentStartDate1;
            $scope.calc.year2RentStartDate1 = response.data.years.year2RentStartDate1;
            $scope.calc.year3RentStartDate1 = response.data.years.year3RentStartDate1;
            $scope.calc.year4RentStartDate1 = response.data.years.year4RentStartDate1;
            $scope.calc.year5RentStartDate1 = response.data.years.year5RentStartDate1;

            if ($scope.calc.year2RentStartDate1 !== null && $scope.calc.year2NumRents < 1) {
                $scope.calc.year2NumRents = 1;
            }

            if ($scope.calc.year3RentStartDate1 !== null && $scope.calc.year3NumRents < 1) {
                $scope.calc.year3NumRents = 1;
            }

            if ($scope.calc.year3RentStartDate1 !== null && $scope.calc.year3NumRents < 1) {
                $scope.calc.year3NumRents = 1;
            }

            if ($scope.calc.year4RentStartDate1 !== null && $scope.calc.year4NumRents < 1) {
                $scope.calc.year4NumRents = 1;
            }
        });
    };

    $scope.init = function () {
        $scope.calc.rcap = RCAP;
        $scope.getDates();
    };

}]);